.verifaction-box {
  margin-top: 30px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.verifaction-box .btn-white {
  box-shadow: 5px 10px 20px 0px #d3d1d84d;
  width: 38px;
  height: 38px;
  margin-bottom: 75px;
}
.verifaction-box h1 {
  font-weight: 400;
  color: #686868;
  font-size: 36.41px;
}
.verifaction-box p {
  font-size: 14px;
  color: #9796a1;
  line-height: 19.28px;
}
.verifaction-box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.verifaction-box-input .form-control {
  border: 1px solid #eeeeee;
  width: 65px;
  height: 65px;
  box-shadow: 15px 20px 45px 0px #e9e9e940 !important;
  border-radius: 10px;
  font-size: 27.2px;
  color: #686868;
  text-align: center;
}
.verifaction-box-input .form-control::placeholder {
  font-size: 27.2px;
  color: #686868;
}
.verifaction-box-input .form-control:focus {
  border-color: #475467;
}
.verifaction-box h5 {
  font-size: 16px;
  color: #686868;
  line-height: 20.16px;
  font-weight: 500;
  text-align: center;
}
.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 20px;
}
.otp-green {
  color: #fff !important;
  background-color: green;
}
.otp-red {
  background-color: red;
  color: #fff !important;
}

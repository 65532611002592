.register-canteen-box .form-md {
  width: 100%;
  text-align: center;
  margin: 32px auto 32px auto;
}

.register-canteen-box .form-group {
  margin-bottom: 20px;
  position: relative;
}
.register-canteen-box input,
.form-select {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  background-color: transparent;
  margin: 0px auto;
  /* / padding: 12px 16px 12px 16px; / */
  height: 50px;
  outline: none !important;
  font-size: 17px;
  color: #475467;
  box-shadow: 15px 20px 45px 0px #e9e9e940 !important;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.register-canteen-box label {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 12.13px;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  color: #9796a1;
  margin: 0px auto;
  cursor: text;
  z-index: 1;
  transition: all 0.15s ease-in-out;
}
.register-canteen-box input:hover,
.register-canteen-box input:focus {
  border: 1px solid #eeeeee;
}

.register-canteen-box input:valid + label,
.register-canteen-box .form-select:valid + label,
.register-canteen-box input:focus + label,
.register-canteen-box .form-select:focus + label {
  top: -6px;
  color: #9796a1;
}

.register-canteen-box .form-md .btn-dark {
  background-color: #686868;
  font-size: 18px;
  line-height: 18px;
  border-radius: 28.5px;
  width: 100%;
  height: 45px;
  border-color: transparent;
  margin-top: 150px;
  letter-spacing: 2.2px;
}
.delete-btns {
  position: absolute;
  top: 5px;
  right: 15px;
}
.uplod-btn {
  position: absolute;
  top: 15px;
  left: 15px;
}
::-webkit-file-upload-button {
  display: none;
}

.add-btn {
  border: 1px solid #eeeeee !important;
  border-radius: 10px !important;
  background-color: transparent !important;
  margin: 0px auto !important;
  padding: 12px 16px 12px 16px !important;
  height: 50px;
  outline: none !important;
  width: 100% !important;
  height: 45px !important;

  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 100% !important;
}

.imgDtata {
  position: relative !important;
}

.imageContainer {
  position: relative !important;
}

/* Dashboard */
body {
  background-color: #f8f8f8 !important;
}
.btn {
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  .main-content {
    left: 78px !important;
    width: calc(100% - 78px) !important;
  }
  .home-content .bx-menu {
    margin-left: 5px !important;
    margin-right: 0px !important;
  }
  .home-section {
    position: relative;
    background: #e4e9f7;
    left: 285px;
    width: calc(100% - 162px);
    transition: all 0.5s ease;
  }
  .sidebar {
    width: 285px;
  }
  .header {
    padding: 0px !important;
  }
  .card-body p {
    display: block !important;
  }
  .user {
    text-align: center;
  }
  .card-header {
    padding: 10px !important;
    display: block;
  }
  .card-body {
    padding: 10px;
  }
  .btn {
    white-space: nowrap;
    font-size: 14px;
  }
  .card-footer {
    padding: 10px !important;
  }
}

.ContentWrap1 {
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
  margin-top: 10px;
}
.ContentWrap2 {
  width: 100%;
  height: 425px;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
  margin-top: 10px;
  border-radius: 1rem !important;
}
.ContentWrap3 {
  width: 100%;
  height: 425px;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
  margin-top: 10px;
  border-radius: 1rem !important;
}
.ContentWrap4 {
  width: 100%;
  height: 425px;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
  margin-top: 10px;
  border-radius: 1rem !important;
}
.scrollX {
  overflow-y: auto;
}

@media screen and (min-device-width: 320px) and (max-device-width: 375px) {
  .ContentWrap3 {
    overflow: scroll;
  }
}
.growthArrow {
  font-weight: bolder;
  font-size: 15px;
  color: rgb(0, 187, 0);
}
.downfallArrow {
  font-weight: bolder;
  font-size: 15px;
  color: rgb(238, 0, 32);
}
.compareText {
  font-size: 13px;
  margin-top: -20px;
  font-weight: 400;
  color: #888;
}

.Number-container {
  width: 100%;
  height: 100px;
  background-color: #d9ecff;
  color: #004b9a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Number-container h1 {
  color: #004b9a;
  margin-top: 10px;
}
.rowBottom {
  border-bottom: 1px solid lightgray;
}
.rowBottom td p {
  font-size: 16px;
  margin-top: 10px;
}

.ant-timeline-item-head-blue {
  color: #26ae61;
  border-color: #26ae61;
  background-color: #26ae61;
}

.timelineHeading {
  color: #aaa;
}

.timelineContent {
  color: #3d464d;
}

.productName {
  margin-bottom: 0;
  font-weight: 500;
}
.reviewBy {
  margin-bottom: 0;
  font-size: 14px;
  opacity: 0.7;
}
.reviewStar {
  font-size: 20px;
  color: rgb(255, 153, 0);
}

.fontSize {
  font-size: 1.3em;
}
/* / job / */

/* / job seeker / */
.card {
  border-radius: 1rem !important;
  /* height: 100%; */
}
.task-box1 {
  position: relative;
  overflow: hidden;
  background-color: #686868;
  color: #fff;
  border-radius: 1rem !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.task-box1 h5 {
  color: #fff;
  text-align: center;
}

.task-box1::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50px;
  right: -30px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.2;
}
.ant-spin-dot-item {
  background-color: #26ae61;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  min-width: 500px;
  width: 100%;
}
.table-scroll .fpBGKh {
  height: 380px;
}

.main-tittle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-warning {
  background-color: #051d34 !important;
  box-shadow: none !important;
  border-color: transparent !important;
  outline: none !important;
  color: #fff !important;
}
.btn-warning:hover {
  background-color: #051d34 !important;
}
.btn-secess:hover {
  background-color: #051d34 !important;
}
.btn-secess {
  background-color: #051d34 !important;
  box-shadow: none !important;
  border-color: transparent !important;
  outline: none !important;
  color: #fff !important;
}
input[type="date"]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
}
.table-green-label {
  background-color: #d2f7d1 !important;
  color: #68e365 !important;
  border-color: transparent !important;
}
.action-btns svg {
  font-size: 22px;
  cursor: pointer;
}
.table-red-label {
  background-color: #fee6ea !important;
  color: #f72b50 !important;
  border-color: transparent !important;
}

.close-icon {
  color: #f72b50 !important;
  font-size: 80px;
}
.edit-btn {
  margin: 0 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #e7fbe6;
  border-color: #e7fbe6;
  color: #68e365;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-btn {
  margin: 0 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #d9f6f3;
  border-color: #d9f6f3;
  color: #145650;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-btn {
  margin: 0 4px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fee6ea;
  border-color: #fee6ea;
  color: #f72b50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addEditModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .addEditModal .modal-content {
    position: relative;
    display: flex;
    left: 6px;
    flex-direction: column;
    width: 95% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 768px) {
  .addEditModal .modal-content {
    position: relative;
    display: flex;
    left: 6px;
    flex-direction: column;
    width: 100% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
  }
}
.tag-input {
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 0 8px;
  border: 1px solid #d6d8da;
  border-radius: 6px;
}

.tag-input input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0;
}

.tag-input input:focus {
  outline: transparent;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}

.tag-title {
  margin-top: 3px;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.viewmessage .modal-content {
  width: 600px !important;
}
.react-tel-input {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
}
.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 18.5px 14px 18.5px 58px !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.faZveH {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  max-width: 100%;
  min-width: 100px;
  width: 50px;
}

.view-popup .fpBGKh {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: scroll !important;
  min-height: 0;
  height: 500px;
}

.bg-violet {
  background-color: #051d34 !important;
}

.imageContainer {
  position: relative;
  width: fit-content;
}

.imageContainer button {
  position: absolute;
  right: 0;
  border: none;
  border-radius: 50px;
  background-color: red;
  color: white;
  font-weight: 600;
  font-family: sans-serif !important;
}
.image-input {
  width: 130px !important;
  height: 130px !important;
  object-fit: contain;
}
.slick-prev {
  left: 0;
}
.slick-next {
  right: 0;
}
.slick-prev,
.slick-next {
  color: black !important;
}
.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}
.css-1xjda1k-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100% !important;
}
.css-qvtrhg-MuiDataGrid-virtualScroller {
  overflow: auto;
  height: 300px !important;
  position: relative;
  z-index: 0;
}
#cell-1-undefined {
  width: 50px !important;
}

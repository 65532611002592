/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 315px;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 30px;
  color: #11101d;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #000;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
  display: block;
}
.sidebar .nav-links > li.active:before,
.sidebar .nav-links > li:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 4px;
  height: 100%;
  background: #051d34;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  border-top-right-radius: 5px;
  border-top-right-radius: 5px;
}
.sidebar .nav-links li.active:before,
.sidebar .nav-links li:hover:before {
  opacity: 1;
}
.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.sidebar .nav-links li svg {
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #11101d;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li.active i,
.sidebar .nav-links li:hover i {
  color: #051d34;
}
.sidebar .nav-links li.showMenu .arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links .arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px;
}
.sidebar .nav-links li a .link_name {
  font-size: 16px;
  font-weight: 400;
  color: #11101d;
  transition: all 0.4s ease;
}
.sidebar .nav-links li.active a .link_name,
.sidebar .nav-links li:hover a .link_name {
  color: #051d34;
  font-weight: 600;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #fff;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #1d1b31;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 78px;
  top: -25px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 315px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}
.sidebar .profile-details .job {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
  white-space: nowrap;
}
.sidebar .profile-details i.bx {
  min-width: 50px;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  left: 315px;
  width: calc(100% - 315px);
  transition: all 0.5s ease;
}
.sidebar.close ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
}
.main-content {
  position: relative;
  transition: all 0.5s ease;
}
.home-content {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-content .bx-menu,
.home-content .text {
  color: #11101d;
  font-size: 28px;
}
.home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
  position: absolute;
}
.home-content .text {
  font-size: 26px;
  font-weight: 600;
}
.header .btn-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  color: #000;
  font-size: 24px;
}
.header .dropdown-toggle::after {
  display: none;
}
@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* @media screen and (min-device-width: 320px) and (max-device-width: 375px) {
  .home-content .bx-menu {
    margin: 0px 0px !important;
    cursor: pointer;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 244px;
    background-color: #fff;
    z-index: 100;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.5s ease;
  }
  .header {
    padding: 0px !important;
  }
  .home-section {
    position: relative;
    background: #E4E9F7;
    left: 243px;
    width: calc(100% - 246px);
    transition: all 0.5s ease;
  }
  }
 */
